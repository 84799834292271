<template>
  <div>
    <v-checkbox
      class="panel-checkbox"
      multiple
      hide-details
      v-for="value in filter"
      :key="value.id"
      :value="value.id"
      :disabled="value.filteredCount == 0"
      v-model="selectedFacetsXS[facet.parameterName]"
      :label="value.name"
      @click="handleFacetFilterMobile()"
    >
      <template v-slot:append>{{ value.filteredCount }}</template>
    </v-checkbox>
    <a
      v-if="facet.values.length > 6 && panelLength == 6"
      class="caption"
      @click="panelLength = facet.values.length"
    >
      Mostra di più
    </a>
    <a
      v-else-if="facet.values.length > 6"
      class="caption"
      @click="panelLength = 6"
    >
      Mostra di meno
    </a>
  </div>
</template>
<script>
import cloneDeep from "lodash/cloneDeep";

export default {
  name: "FilterCheckboxList",
  data() {
    return {
      panelLength: 6
    };
  },
  props: {
    facet: { type: Object },
    facetsFilter: { type: Object }
  },
  computed: {
    selectedFacetsXS() {
      return cloneDeep(this.facetsFilter);
    },
    filter() {
      return this.facet.values.slice(0, this.panelLength);
    }
  },
  methods: {
    handleFacetFilterMobile() {
      this.$emit("handleFacetFilterMobile", this.selectedFacetsXS);
    }
  }
};
</script>
