<template>
  <div class="promo-filter py-4">
    <v-switch
      v-if="!hidePromoFilter"
      v-model="promoFilter"
      inset
      label="In promozione"
      dense
      class="pt-2 mt-0"
      hide-details
    ></v-switch>

    <v-switch
      v-if="!hideNewFilter"
      v-model="newFilter"
      inset
      label="Novità"
      dense
      class="pt-2 mt-0"
      hide-details
    ></v-switch>

    <v-switch
      v-if="!hidePreferredFilter"
      v-model="preferredFilter"
      inset
      label="Preferiti"
      dense
      class="pt-2 mt-0"
      hide-details
    ></v-switch>
  </div>
</template>

<script>
export default {
  name: "ProductPromoFilter",
  props: ["hideNewFilter", "hidePromoFilter", "hidePreferredFilter"],
  computed: {
    promoFilter: {
      get() {
        return this.$store.state.category.promoFilter;
      },
      set(value) {
        this.$store.dispatch("category/setFilterPromo", value);
        this.$emit("filterChanged");
      }
    },
    newFilter: {
      get() {
        return this.$store.state.category.newFilter;
      },
      set(value) {
        this.$store.dispatch("category/setFilterNew", value);
        this.$emit("filterChanged");
      }
    },
    preferredFilter: {
      get() {
        return this.$store.state.category.preferredFilter;
      },
      set(value) {
        this.$store.dispatch("category/setFilterPreferred", value);
        this.$emit("filterChanged");
      }
    }
  }
};
</script>

<style></style>
