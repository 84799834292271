<template>
  <v-list dense class="category-menu">
    <v-subheader class="text-primary d-none d-sm-flex">
      <h3>{{ category.name }}</h3>
    </v-subheader>
    <v-list-item-group color="primary"></v-list-item-group>
    <v-list-item
      class="list-item px-0"
      v-for="category2 in category.children"
      :key="category2.categoryId"
    >
      <router-link
        :to="{ name: 'Category', params: { pathMatch: category2.slug } }"
      >
        {{ category2.name }}
      </router-link>
    </v-list-item>
  </v-list>
</template>
<style lang="scss">
.category-menu {
  background-color: transparent !important;
  h3 {
    font-weight: bold;
    font-size: 13px;
    text-transform: uppercase;
    color: $primary;
  }
  .v-subheader {
    padding-left: 0px;
  }
  .list-item {
    min-height: initial;
  }
  a {
    font-size: 13px;
    line-height: 24px;
    color: $text-color;
    text-decoration: none;
  }
}
</style>
<script>
import deliveryReactive from "~/mixins/deliveryReactive";

export default {
  name: "CategoryMenu",
  mixins: [deliveryReactive],
  props: { category: { type: Object, required: true } }
};
</script>
