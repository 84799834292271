<template>
  <!-- <v-row no-gutters> -->
  <div class="d-flex flex-row">
    <span class="d-none d-sm-flex align-center mr-3" v-if="wishlistItem"
      >{{ $t("sort") }}:</span
    >
    <v-select
      :label="$t('sort')"
      solo
      flat
      dense
      class="pt-0"
      :class="wishlistItem ? '' : 'ps-4'"
      :items="sortOptions"
      v-model="sortFilter"
      @change="fetchProducts"
      hide-details
    />
  </div>
  <!-- </v-row> -->
</template>

<script>
export default {
  name: "ProductSortFilter",
  data() {
    return {
      sortOptions: [
        { value: "", text: "Rilevanza", icon: "ion-arrow-up-c" },
        { value: "pri_asc", text: "Prezzo crescente", icon: "ion-arrow-up-c" },
        {
          value: "pri_des",
          text: "Prezzo decrescente",
          icon: "ion-arrow-down-c"
        },
        {
          value: "pri_um_asc",
          text: "Prezzo al kg crescente",
          icon: "ion-arrow-down-c"
        },
        {
          value: "pri_um_des",
          text: "Prezzo al kg descrescente",
          icon: "ion-arrow-down-c"
        },
        {
          value: "alf_asc",
          text: "Alfabetico crescente",
          icon: "ion-arrow-up-c"
        }
      ]
    };
  },
  computed: {
    sortFilter: {
      get() {
        return this.$store.state.category.sortFilter;
      },
      set(value) {
        this.$store.dispatch("category/setFilterSort", value);
        this.$emit("filterChanged");
      }
    }
  }
};
</script>
